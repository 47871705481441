<template>
  <IonPage>
    <FtrHeader :helpText="helptext_3dscan_page"></FtrHeader>

    <IonContent :color="store.url.includes('kievit') ? 'white' : 'light'" style="
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: 10px;
      ">
      <ion-grid>
        <ion-row>
          <!-- Left Foot Viewer -->
          <ion-col size-xs="12" size-sm="6" size-md="5" size-lg="5" size-xl="5">
            <ion-card style="
                padding: 0;
                margin-top: 8px;
                margin-left: 8px;
                margin-right: 0;
              " class="custom-card">
              <ion-card-content style="padding: 12px">
                <!-- Right side: 3D Viewer -->
                <ion-grid>
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="11" style="padding: 0; margin: 0">
                      <div style="
                          border: 1px solid var(--ion-color-dark);
                          border-radius: 6px;
                        ">
                        <div style="min-height: 70vh; border-radius: 5px" ref="target_left"></div>

                        <q-inner-loading :showing="visible">
                          <q-spinner-hourglass size="50px" style="color: #d87c1f"/>
                        </q-inner-loading>
                      </div>
                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="1" style="padding: 0; margin: 0">
                      <div style="
                          display: flex;
                          flex-wrap: wrap;
                          justify-content: space-evenly;
                        ">
                        <ion-button @click="rotateModelOnZPlane('left', 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-left"></i>
                        </ion-button>
                        <ion-button @click="rotateModelOnZPlane('left', -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-right"></i>
                        </ion-button>

                        <ion-button @click="moveModelOnZPlane('left', -1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-left"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-right"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 0, -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-up"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('left', 0, 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-down"></i>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <ion-col size-xs="12" size-sm="6" size-md="2" size-lg="2" size-xl="2">
            <ion-card v-if="width_left && width_right" class="custom-card" style="
                padding: 0;
                margin-top: 8px;
                margin-left: 8px;
                margin-right: 8px;
                margin-bottom: 16px;
              ">
              <ion-card-content style="
                  padding-inline-start: 10px;
                  padding-inline-end: 10px;
                  display: flex;
                  flex-direction: column;
                ">
                <q-markup-table separator="vertical" dense flat :wrap-cells="true" style="width: 100%">
                  <thead>
                  <tr>
                    <th style="
                          text-align: center;
                          font-size: 10px;
                          font-weight: bold;
                        "></th>
                    <th style="
                          padding: 0;
                          text-align: center;
                          font-size: 10px;
                          font-weight: bold;
                        ">
                      Links
                    </th>
                    <th style="
                          padding: 0;
                          text-align: center;
                          font-size: 10px;
                          font-weight: bold;
                        ">
                      Rechts
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">
                      Lengte:
                    </td>
                    <td style="padding: 0; text-align: center; font-size: 12px">
                      {{ Math.round(length_left) }}
                    </td>
                    <td style="padding: 0; text-align: center; font-size: 12px">
                      {{ Math.round(length_right) }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">
                      Omvang:
                    </td>
                    <td style="padding: 0; text-align: center; font-size: 12px">
                      {{ Math.round(circumference_left) }}
                    </td>
                    <td style="padding: 0; text-align: center; font-size: 12px">
                      {{ Math.round(circumference_right) }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding: 0; font-size: 10px; font-weight: bold">
                      Breedte:
                    </td>
                    <td style="padding: 0; text-align: center; font-size: 12px">
                      {{ Math.round(width_left) }}
                    </td>
                    <td style="padding: 0; text-align: center; font-size: 12px">
                      {{ Math.round(width_right) }}
                    </td>
                  </tr>
                  </tbody>
                </q-markup-table>
              </ion-card-content>
            </ion-card>
            <ion-card class="custom-card" style="padding: 0; margin: 8px">
              <ion-card-content style="display: flex; flex-direction: column">
                <ion-button color="secondary" v-if="!files_loaded_left || !files_loaded_right" @click="setOpen(true, 'upload')">
                  <i style="padding-right: 6px" class="fa-regular fa-cloud-arrow-up"></i>
                  Upload STL
                </ion-button>
                <ion-button color="secondary" v-if="!files_loaded_left || !files_loaded_right" @click="setOpen(true, 'library')" style="margin-top: 16px">
                  <i style="padding-right: 6px" class="fa-regular fa-rectangle-list"></i>
                  Bibliotheek
                </ion-button>
                <ion-button color="secondary" v-if="!files_loaded_left || !files_loaded_right" @click="showCameraPreview" style="margin-top: 16px">
                  <i style="padding-right: 6px" class="fa-regular fa-rectangle-list"></i>
                  Scan
                </ion-button>
                <ion-button v-if="files_loaded_left && files_loaded_right" color="danger" style="margin-top: 16px" @click="removeScans">
                  <i style="padding-right: 6px" class="fa-regular fa-eraser"></i>
                  Remove Scans
                </ion-button>
                <ion-button color="secondary" v-if="files_loaded_left && files_loaded_right" @click="navigate" style="margin-top: 16px">
                  <i style="padding-right: 6px" class="fa-regular fa-chevron-right"></i>Verder
                </ion-button>
              </ion-card-content>
            </ion-card>
          </ion-col>

          <!-- Right Foot Viewer -->
          <ion-col size-xs="12" size-sm="6" size-md="5" size-lg="5" size-xl="5">
            <ion-card style="
                padding: 0;
                margin-top: 8px;
                margin-left: 0;
                margin-right: 8px;
              " class="custom-card">
              <ion-card-content style="padding: 12px">
                <!-- Right side: 3D Viewer -->
                <ion-grid>
                  <ion-row>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="11" style="padding: 0; margin: 0">
                      <div style="
                          border: 1px solid var(--ion-color-dark);
                          border-radius: 6px;
                        ">
                        <div style="min-height: 70vh; border-radius: 5px" ref="target_right"></div>

                        <q-inner-loading :showing="visible">
                          <q-spinner-hourglass size="50px" style="color: #d87c1f"/>
                        </q-inner-loading>
                      </div>
                    </ion-col>
                    <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" size-xl="1" style="padding: 0; margin: 0">
                      <div style="
                          display: flex;
                          flex-wrap: wrap;
                          justify-content: space-evenly;
                        ">
                        <ion-button @click="rotateModelOnZPlane('right', 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-left"></i>
                        </ion-button>
                        <ion-button @click="rotateModelOnZPlane('right', -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-rotate-right"></i>
                        </ion-button>

                        <ion-button @click="moveModelOnZPlane('right', -1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-left"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 1, 0)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-right"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 0, -1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-up"></i>
                        </ion-button>
                        <ion-button @click="moveModelOnZPlane('right', 0, 1)" shape="round" size="large" fill="clear">
                          <i style="font-size: 24px" slot="icon-only" class="fa-regular fa-arrow-down"></i>
                        </ion-button>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-modal :is-open="isOpen">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click="setOpen(false, null)">Cancel</ion-button>
            </ion-buttons>
            <ion-buttons slot="end" v-if="contentType === 'upload'">
              <ion-button @click="saveScans" :strong="true">Confirm</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content v-if="contentType === 'upload'" class="ion-padding">
          <div style="
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            ">
            <q-input label="Klantnummer/geboortedatum" v-model="clientIdOne" ref="inputRef" type="text" name="clientIdOne" style="width: 40%"/>
            <q-input label="Eerste letters achternaam" v-model="clientIdTwo" ref="inputRef" type="text" name="clientIdTwo" style="width: 40%"/>
          </div>
          <ion-toolbar style="margin-top: 16px; text-align: center">
            <ion-title>STL Files</ion-title>
          </ion-toolbar>
          <div style="
              display: flex;
              flex-direction: row;
              justify-content: space-evenly;
            ">
            <q-uploader @added="onAdded($event, 'left')" hide-upload-btn label="Links" style="width: 40%"/>
            <q-uploader @added="onAdded($event, 'right')" hide-upload-btn label="Rechts" style="width: 40%"/>
          </div>
        </ion-content>
        <ion-content v-if="contentType === 'library'" class="ion-padding">
          <q-list class="rounded-borders">
            <q-separator spaced/>
            <template v-for="item in library" :key="item.id">
              <q-item @click="loadScan(item.id)" clickable v-ripple>
                <q-item-section avatar top>
                  <q-avatar color="grey" text-color="white">
                    <i style="font-size: 22px" class="fal fa-chart-scatter-3d"></i>
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label style="font-weight: bold" lines="1">{{ item.client_id_two + " " + item.client_id_one }}
                  </q-item-label>
                  <q-item-label caption>{{ normalizeDate(item.date) }}
                  </q-item-label>
                  <q-item-label>Files:</q-item-label>
                  <q-item-label style="margin-left: 16px" caption>{{ item.filename_left }}
                  </q-item-label>
                  <q-item-label style="margin-left: 16px" caption>{{ item.filename_right }}
                  </q-item-label>
                </q-item-section>

                <q-item-section side>
                  <i style="font-size: 22px" class="fal fa-arrow-right"></i>
                </q-item-section>
              </q-item>
              <q-separator spaced/>
            </template>
          </q-list>
        </ion-content>
      </ion-modal>
    </IonContent>
  </IonPage>
</template>
<script setup>
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonModal,
  IonPage,
  IonRow,
  IonToolbar,
  IonTitle,
} from "@ionic/vue";
import {QMarkupTable} from "quasar";
import {DataStore, Predicates, SortDirection} from "aws-amplify/datastore";
import {downloadData, uploadData} from "aws-amplify/storage";
import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {STLLoader} from "three/examples/jsm/loaders/STLLoader";
import {onBeforeUnmount, onMounted, ref} from "vue";
import {useRouter, useRoute} from "vue-router";
import outline_left from "../assets/outline_L.png";
import outline_right from "../assets/outline_R.png";
import FtrHeader from "../components/FtrHeader.vue";
import {helptext_3dscan_page} from "../locales/HelptTextContent";
import {DevFittr3DScans} from "../models";
import {useGlobalStore} from "../store/global";

import {FeetScanner} from '../../feetscanner/src/index.js';

const showCameraPreview = async () => {
  try {
    const result = await FeetScanner.openViewController();
    console.log(result.message); // Logs: "ViewController opened successfully."
  } catch (error) {
    console.error('Error opening ViewController:', error);
  }
  //const result = await FeetScanner.openScanPreview();
  //console.log(result);
  //try {
  //  const result = await FeetScanner.openScanPreview();
  //  console.log(result);
  //  const { filePath } = result;
  //
  //  // Fetch the file from the local file system
  //  const response = await fetch(filePath);
  //  const blob = await response.blob();
  //
  //  // Upload to Amplify Storage
  //  const fileName = 'depthData.stl';
  //
  //  try {
  //    const saved = uploadData({
  //      path: "3DScans/" + fileName,
  //      data: blob,
  //      options: {
  //        onProgress: ({transferredBytes, totalBytes}) => {
  //          if (totalBytes) {
  //            console.log(
  //                `Upload progress ${Math.round(
  //                    (transferredBytes / totalBytes) * 100
  //                )} %`
  //            );
  //          }
  //        },
  //      },
  //    });
  //    console.log(saved)
  //  } catch (error) {
  //    console.log("Error : ", error);
  //  }
  //
  //  console.log('File uploaded successfully!');
  //} catch (error) {
  //  console.error('Error:', error);
  //}
};

const store = useGlobalStore();
const router = useRouter();
const route = useRoute();

const visible = ref(false);
let rotationTimeout;

const isOpen = ref(false);
const contentType = ref("");
const library = ref();

const setOpen = async (open, content) => {
  if (content === "upload") {
    contentType.value = "upload";
  } else if (content === "library") {
    library.value = await DataStore.query(DevFittr3DScans, Predicates.ALL, {
      sort: (s) => s.date(SortDirection.DESCENDING),
    });
    contentType.value = "library";
  }
  isOpen.value = open;
};

const clientIdOne = ref("");
const clientIdTwo = ref("");
const filename_left = ref("");
const filename_right = ref("");
const file_left = ref();
const file_right = ref();

const width_left = ref();
const circumference_left = ref();
const length_left = ref();

const width_right = ref();
const circumference_right = ref();
const length_right = ref();

const files_loaded_left = ref(false);
const files_loaded_right = ref(false);

function normalizeDate(date) {
  const toNormalize = new Date(date);
  return (
      (toNormalize.getDate() > 9
          ? toNormalize.getDate()
          : "0" + toNormalize.getDate()) +
      "-" +
      (toNormalize.getMonth() > 8
          ? toNormalize.getMonth() + 1
          : "0" + (toNormalize.getMonth() + 1)) +
      "-" +
      toNormalize.getFullYear() +
      " " +
      (toNormalize.getHours() > 9
          ? toNormalize.getHours()
          : "0" + toNormalize.getHours()) +
      ":" +
      (toNormalize.getMinutes() > 9
          ? toNormalize.getMinutes()
          : "0" + toNormalize.getMinutes())
  );
}

async function saveScans() {
  const category = JSON.parse(route.params.category);
  const user = await store.getLoggedInUser();
  console.log(user);
  visible.value = true;
  const date = Date.now();
  await DataStore.save(
      new DevFittr3DScans({
        client_id_one: clientIdOne.value,
        client_id_two: clientIdTwo.value,
        user: user.email,
        gender: category.category,
        filename_left: filename_left.value,
        filename_right: filename_right.value,
        organisation: user["custom:organisation_id"],
        date: date,
      })
  );
  await setOpen(false);
  if (file_left.value && file_left.value[0]) {
    loadSTL("left", file_left.value[0]);
  }
  if (file_right.value && file_right.value[0]) {
    loadSTL("right", file_right.value[0]);
  }
}

async function loadScan(id) {
  visible.value = true;
  const scan = await DataStore.query(DevFittr3DScans, id);
  const fileL = await downloadScan(scan.filename_left);
  const fileR = await downloadScan(scan.filename_right);
  await setOpen(false);
  loadSTL("left", fileL.body);
  loadSTL("right", fileR.body);
}

async function downloadScan(filename) {
  return await downloadData({
    path: "3DScans/" + filename,
    options: {
      onProgress: (event) => {
        console.log(event.transferredBytes);
      },
    },
  }).result;
}

async function onAdded(files, side) {
  if (side === "left") {
    filename_left.value = files[0].name;
    file_left.value = files;
  } else if (side === "right") {
    filename_right.value = files[0].name;
    file_right.value = files;
  }
  const reader = new FileReader();
  const objectUrl = URL.createObjectURL(files[0]);
  reader.onload = (e) => {
    URL.revokeObjectURL(objectUrl);
    try {
      uploadData({
        path: "3DScans/" + files[0].name,
        data: files[0],
        options: {
          onProgress: ({transferredBytes, totalBytes}) => {
            if (totalBytes) {
              console.log(
                  `Upload progress ${Math.round(
                      (transferredBytes / totalBytes) * 100
                  )} %`
              );
            }
          },
        },
      });
    } catch (error) {
      console.log("Error : ", error);
    }
  };
  reader.readAsArrayBuffer(files[0]);
}

const raam = window.location;

const target_left = ref(null);
const target_right = ref(null);

let scene_left,
    camera_left,
    renderer_left,
    controls_left,
    footModel_left = null;
let scene_right,
    camera_right,
    renderer_right,
    controls_right,
    footModel_right = null;

// Dragging related variables
let isDraggingLeft = false;
let isDraggingRight = false;
const mouse = new THREE.Vector2();
const raycaster = new THREE.Raycaster();
let dragStartPositionLeft = new THREE.Vector3();
let dragStartPositionRight = new THREE.Vector3();

function createSceneAndRenderer(container) {
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(0xffffff);

  const camera = new THREE.PerspectiveCamera(
      40,
      container.clientWidth / container.clientHeight,
      0.1,
      5000
  );
  camera.position.set(0, 500, 0);
  camera.lookAt(0, 0, 0);
  camera.up.set(0, 1, 0);

  const renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.shadowMap.enabled = true;

  const ambientLight = new THREE.AmbientLight(0xffffff, 1.2);
  scene.add(ambientLight);

  const directionalLight1 = new THREE.DirectionalLight(0xffffff, 2);
  directionalLight1.position.set(50, 50, 100);
  directionalLight1.castShadow = true;
  scene.add(directionalLight1);

  const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1.5);
  directionalLight2.position.set(-50, 50, -100);
  directionalLight2.castShadow = true;
  scene.add(directionalLight2);

  const directionalLight3 = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight3.position.set(0, 100, 0);
  directionalLight3.castShadow = true;
  scene.add(directionalLight3);

  const gridHelper = new THREE.GridHelper(400, 40);
  scene.add(gridHelper);

  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enableDamping = true;
  controls.dampingFactor = 0.05;

  const resizeRendererToFixedAspectRatio = () => {
    const containerWidth = container.clientWidth;
    const containerHeight = container.clientHeight;
    if (containerWidth > 0 && containerHeight > 0) {
      renderer.setSize(containerWidth, containerHeight);
      camera.aspect = containerWidth / containerHeight;
      camera.updateProjectionMatrix();
    }
  };

  const resizeObserver = new ResizeObserver(resizeRendererToFixedAspectRatio);
  resizeObserver.observe(container);
  onBeforeUnmount(() => resizeObserver.disconnect());

  resizeRendererToFixedAspectRatio();

  return {scene, camera, renderer, controls};
}

function loadFootOutline(side, scene) {
  const outline_image = side === "left" ? outline_left : outline_right;
  const textureLoader = new THREE.TextureLoader();
  textureLoader.load(outline_image, (texture) => {
    const planeGeometry = new THREE.PlaneGeometry(150, 300);
    const planeMaterial = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
    });
    const footOutline = new THREE.Mesh(planeGeometry, planeMaterial);
    footOutline.rotation.x = -Math.PI / 2;
    footOutline.position.set(0, 0.1, 0);
    scene.add(footOutline);
  });
}

function addMarker(scene, position, color = 0xffff00, markerRadius = 2) {
  const geometry = new THREE.SphereGeometry(markerRadius, 16, 16);
  const material = new THREE.MeshBasicMaterial({color});
  const sphere = new THREE.Mesh(geometry, material);
  sphere.position.copy(position);
  scene.add(sphere);
  return sphere;
}

function drawLineBetweenPoints(scene, p1, p2, color = 0xffffff) {
  const geometry = new THREE.BufferGeometry().setFromPoints([p1, p2]);
  const material = new THREE.LineBasicMaterial({color});
  const line = new THREE.Line(geometry, material);
  scene.add(line);
  return line;
}

function drawHorizontalLine(side, scene) {
  // Define start/end points
  const lineStart = side === "left"
      ? new THREE.Vector3(-75, 0.1, -31)
      : new THREE.Vector3(-75, 0.1, -63);
  const lineEnd = side === "left"
      ? new THREE.Vector3(75, 0.1, -63)
      : new THREE.Vector3(75, 0.1, -31);

  const lineGeometry = new THREE.BufferGeometry().setFromPoints([lineStart, lineEnd]);
  const lineMaterial = new THREE.LineBasicMaterial({color: 0xff0000});
  const horizontalLine = new THREE.Line(lineGeometry, lineMaterial);
  scene.add(horizontalLine);

  // Add markers at both ends of the horizontal line
  addMarker(scene, lineStart, 0xff0000, 2); // Medial marker
  addMarker(scene, lineEnd, 0xff0000, 2);   // Lateral marker

  // Return these points so we can connect them from the vertical line function
  return {lineStart, lineEnd};
}

function drawVerticalLine(side, scene, horizontalPoints) {
  const lineStart = new THREE.Vector3(0, 0.1, -200);
  const lineEnd = new THREE.Vector3(0, 0.1, 200);
  const lineGeometry = new THREE.BufferGeometry().setFromPoints([lineStart, lineEnd]);
  const lineMaterial = new THREE.LineBasicMaterial({color: 0xff0000});
  const verticalLine = new THREE.Line(lineGeometry, lineMaterial);
  scene.add(verticalLine);

  // Top end markers (vertical line)
  //const topLeftPos = new THREE.Vector3(-40, 0.1, 150); // vertical top medial
  //const topRightPos = new THREE.Vector3(40, 0.1, 150); // vertical top lateral
  //addMarker(scene, topLeftPos, 0xff0000, 2);
  //addMarker(scene, topRightPos, 0xff0000, 2);
  //drawLineBetweenPoints(scene, topLeftPos, topRightPos, 0xff0000);

  // Bottom end markers (vertical line) depend on side
  //const medial_point = side === "left" ? -130 : -160;
  //const lateral_point = side === "left" ? -160 : -130;
  //
  //const bottomLeftPos = new THREE.Vector3(-60, 0.1, medial_point);  // vertical bottom medial
  //const bottomRightPos = new THREE.Vector3(60, 0.1, lateral_point); // vertical bottom lateral
  //addMarker(scene, bottomLeftPos, 0xff0000, 2);
  //addMarker(scene, bottomRightPos, 0xff0000, 2);

  //drawLineBetweenPoints(scene, bottomLeftPos, bottomRightPos, 0xff0000);

  // Now draw lines from top and bottom vertical markers to the corresponding horizontal markers:
  // horizontalPoints.lineStart is medial (negative X)
  // horizontalPoints.lineEnd is lateral (positive X)

  //// Top medial (topLeftPos) to medial horizontal (horizontalPoints.lineStart)
  //drawLineBetweenPoints(scene, topLeftPos, horizontalPoints.lineStart, 0xff0000);
  //
  //// Top lateral (topRightPos) to lateral horizontal (horizontalPoints.lineEnd)
  //drawLineBetweenPoints(scene, topRightPos, horizontalPoints.lineEnd, 0xff0000);
  //
  //// Bottom medial (bottomLeftPos) to medial horizontal (horizontalPoints.lineStart)
  //drawLineBetweenPoints(scene, bottomLeftPos, horizontalPoints.lineStart, 0xff0000);
  //
  //// Bottom lateral (bottomRightPos) to lateral horizontal (horizontalPoints.lineEnd)
  //drawLineBetweenPoints(scene, bottomRightPos, horizontalPoints.lineEnd, 0xff0000);
}

function animateBoth() {
  requestAnimationFrame(animateBoth);
  if (controls_left && scene_left && camera_left && renderer_left) {
    controls_left.update();
    renderer_left.render(scene_left, camera_left);
  }
  if (controls_right && scene_right && camera_right && renderer_right) {
    controls_right.update();
    renderer_right.render(scene_right, camera_right);
  }
}

onMounted(() => {
  if (target_left.value) {
    const setupLeft = createSceneAndRenderer(target_left.value);
    scene_left = setupLeft.scene;
    camera_left = setupLeft.camera;
    renderer_left = setupLeft.renderer;
    controls_left = setupLeft.controls;
    target_left.value.appendChild(renderer_left.domElement);

    target_left.value.addEventListener("mousedown", onMouseDownLeft);
    target_left.value.addEventListener("mousemove", onMouseMoveLeft);
    target_left.value.addEventListener("mouseup", onMouseUpLeft);

    target_left.value.addEventListener("touchstart", onTouchStartLeft);
    target_left.value.addEventListener("touchmove", onTouchMoveLeft);
    target_left.value.addEventListener("touchend", onTouchEndLeft);

    loadFootOutline("left", scene_left);
    const horizontalLeft = drawHorizontalLine("left", scene_left);
    drawVerticalLine("left", scene_left, horizontalLeft);
  }

  if (target_right.value) {
    const setupRight = createSceneAndRenderer(target_right.value);
    scene_right = setupRight.scene;
    camera_right = setupRight.camera;
    renderer_right = setupRight.renderer;
    controls_right = setupRight.controls;
    target_right.value.appendChild(renderer_right.domElement);

    target_right.value.addEventListener("mousedown", onMouseDownRight);
    target_right.value.addEventListener("mousemove", onMouseMoveRight);
    target_right.value.addEventListener("mouseup", onMouseUpRight);

    target_right.value.addEventListener("touchstart", onTouchStartRight);
    target_right.value.addEventListener("touchmove", onTouchMoveRight);
    target_right.value.addEventListener("touchend", onTouchEndRight);

    loadFootOutline("right", scene_right);
    const horizontalRight = drawHorizontalLine("right", scene_right);
    drawVerticalLine("right", scene_right, horizontalRight);
  }

  animateBoth();
});

onBeforeUnmount(() => {
  if (target_left.value) {
    target_left.value.removeEventListener("mousedown", onMouseDownLeft);
    target_left.value.removeEventListener("mousemove", onMouseMoveLeft);
    target_left.value.removeEventListener("mouseup", onMouseUpLeft);

    target_left.value.removeEventListener("touchstart", onTouchStartLeft);
    target_left.value.removeEventListener("touchmove", onTouchMoveLeft);
    target_left.value.removeEventListener("touchend", onTouchEndLeft);
  }

  if (target_right.value) {
    target_right.value.removeEventListener("mousedown", onMouseDownRight);
    target_right.value.removeEventListener("mousemove", onMouseMoveRight);
    target_right.value.removeEventListener("mouseup", onMouseUpRight);

    target_right.value.removeEventListener("touchstart", onTouchStartRight);
    target_right.value.removeEventListener("touchmove", onTouchMoveRight);
    target_right.value.removeEventListener("touchend", onTouchEndRight);
  }
});

function centerAlignAndRotateModelToesForward(footModel, scene) {
  // Step 1: Compute the initial bounding box
  footModel.geometry.computeBoundingBox();
  let boundingBox = footModel.geometry.boundingBox;

  // Step 2: Center the model at the origin
  const center = new THREE.Vector3();
  boundingBox.getCenter(center);
  footModel.position.sub(center);

  // Recompute the bounding box after centering
  footModel.geometry.computeBoundingBox();
  boundingBox = footModel.geometry.boundingBox;

  // Step 3: Move the model above the grid
  const yOffset = boundingBox.min.y;
  footModel.position.y -= yOffset; // Ensure the model is entirely above the grid

  // Step 4: Align the largest flat surface downward
  const vertices = footModel.geometry.attributes.position.array;
  const flatSurfaces = {};
  const threshold = 0.01; // Angular threshold for grouping similar normals

  for (let i = 0; i < vertices.length; i += 9) {
    const v1 = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
    const v2 = new THREE.Vector3(vertices[i + 3], vertices[i + 4], vertices[i + 5]);
    const v3 = new THREE.Vector3(vertices[i + 6], vertices[i + 7], vertices[i + 8]);

    const faceNormal = new THREE.Vector3();
    faceNormal.crossVectors(v2.clone().sub(v1), v3.clone().sub(v1)).normalize();

    const key = `${Math.round(faceNormal.x / threshold)},${Math.round(faceNormal.y / threshold)},${Math.round(faceNormal.z / threshold)}`;
    if (!flatSurfaces[key]) {
      flatSurfaces[key] = {normal: faceNormal, vertices: [], area: 0};
    }

    flatSurfaces[key].vertices.push(v1, v2, v3);
    flatSurfaces[key].area += faceNormal.length() / 2;
  }

  let largestSurface = null;
  let maxArea = 0;
  for (const key in flatSurfaces) {
    if (flatSurfaces[key].area > maxArea) {
      maxArea = flatSurfaces[key].area;
      largestSurface = flatSurfaces[key];
    }
  }

  if (largestSurface) {
    const surfaceNormal = largestSurface.normal;
    const targetNormal = new THREE.Vector3(0, -1, 0); // Downward direction
    const rotationAxis = new THREE.Vector3().crossVectors(surfaceNormal, targetNormal).normalize();
    const rotationAngle = Math.acos(surfaceNormal.dot(targetNormal));

    if (rotationAxis.length() > 0 && rotationAngle > 0) {
      const rotationMatrix = new THREE.Matrix4().makeRotationAxis(rotationAxis, rotationAngle);
      footModel.applyMatrix4(rotationMatrix);
    }
  }

  // Step 5: Recompute the bounding box after rotation
  footModel.geometry.computeBoundingBox();
  boundingBox = footModel.geometry.boundingBox;

  // Step 6: Determine the longest dimension (toes direction)
  const dimensions = new THREE.Vector3();
  boundingBox.getSize(dimensions);

  const longestAxis = dimensions.x >= dimensions.z ? 'x' : 'z';

  // Step 7: Determine which end represents the toes
  let maxCoord = -Infinity;
  let toesVertex = new THREE.Vector3();

  for (let i = 0; i < vertices.length; i += 3) {
    const vertex = new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]);
    if (longestAxis === 'x' && vertex.x > maxCoord) {
      maxCoord = vertex.x;
      toesVertex.copy(vertex);
    } else if (longestAxis === 'z' && vertex.z > maxCoord) {
      maxCoord = vertex.z;
      toesVertex.copy(vertex);
    }
  }

  // Step 8: Calculate the rotation angle to align toes with the positive Z-axis
  const forwardDirection = toesVertex.clone().sub(center).normalize();
  const targetDirection = new THREE.Vector3(0, 0, 1); // Positive Z-axis
  const rotationAngle = Math.atan2(forwardDirection.x, forwardDirection.z);

  const zRotationMatrix = new THREE.Matrix4().makeRotationY(-rotationAngle); // Rotate on Z-plane
  footModel.applyMatrix4(zRotationMatrix);

  // Step 9: Final centering after all transformations
  const finalBoundingBox = new THREE.Box3().setFromObject(footModel);
  const finalCenter = new THREE.Vector3();
  finalBoundingBox.getCenter(finalCenter);
  footModel.position.sub(finalCenter); // Move model center to (0, 0, 0)

  // Step 10: Finalize transformations
  footModel.geometry.computeVertexNormals();
  footModel.updateMatrixWorld(true);

  footModel.position.y = 0;
  // Step 11: Add or update the bounding box helper for visualization
  //const boundingBoxHelper = new THREE.BoxHelper(footModel, 0xff0000); // Red for visibility
  //scene.add(boundingBoxHelper);
  //boundingBoxHelper.update();

  console.log('Model finalized, toes aligned, and centered at (0, 0, 0).');
}

function removeSpheresAndMeasurements(sceneRef) {
  sceneRef.children.slice().forEach((child) => {
    if (child.geometry && child.geometry.type === "SphereGeometry") {
      sceneRef.remove(child);
    }
  });
}

function measureFoot(footModel, scene, side) {
  if (!footModel) {
    console.warn("No foot model loaded.");
    return {width: 0, circumference: 0, length: 0};
  }

  // Remove any existing measurement spheres
  scene.children.slice().forEach((child) => {
    if (child.geometry && child.geometry.type === "SphereGeometry") {
      scene.remove(child);
    }
  });

  // A helper to draw spheres
  function drawSphere(position, color = 0xff0000) {
    const sphere = new THREE.Mesh(
        new THREE.SphereGeometry(1, 8, 8),
        new THREE.MeshBasicMaterial({color})
    );
    sphere.position.copy(position);
    scene.add(sphere);
  }

  // Extract vertex positions in world space
  const geometry = footModel.geometry;
  const positionAttribute = geometry.attributes.position;
  const worldVertices = [];
  const modelMatrix = footModel.matrixWorld;

  for (let i = 0; i < positionAttribute.count; i++) {
    const localVertex = new THREE.Vector3(
        positionAttribute.getX(i),
        positionAttribute.getY(i),
        positionAttribute.getZ(i)
    );
    worldVertices.push(localVertex.applyMatrix4(modelMatrix));
  }

  // Define the reference line for measurement
  // Same line as originally used for the left foot
  const horizontalLineStart =
      side === "left" ? {x: -100, z: -25} : {x: -100, z: -68};
  const horizontalLineEnd =
      side === "left" ? {x: 100, z: -68} : {x: 100, z: -25};

  const inwardStep = 2.0;
  const heightStep = 2.0;
  const maxHeight = 50;
  const startHeight = 0;
  const tolerance = 1.0;

  // For left foot: original approach
  // For right foot: invert scanning direction
  let leftFactor, rightFactor;
  if (side === "left") {
    leftFactor = 0;
    rightFactor = 1;
  } else {
    // Invert scanning direction for the right foot
    leftFactor = 1;
    rightFactor = 0;
  }

  function interpolateZ(x) {
    const factor =
        (x - horizontalLineStart.x) /
        (horizontalLineEnd.x - horizontalLineStart.x);
    return (
        horizontalLineStart.z +
        factor * (horizontalLineEnd.z - horizontalLineStart.z)
    );
  }

  function findVertex(x, z) {
    for (let y = startHeight; y <= maxHeight; y += heightStep) {
      for (const worldVertex of worldVertices) {
        if (
            Math.abs(worldVertex.x - x) <= tolerance &&
            Math.abs(worldVertex.z - z) <= tolerance &&
            Math.abs(worldVertex.y - y) <= tolerance
        ) {
          return worldVertex;
        }
      }
    }
    return null;
  }

  let widestLeftPoint = null;
  let widestRightPoint = null;

  // Scanning loop
  while (true) {
    const leftX =
        horizontalLineStart.x +
        leftFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const leftZ = interpolateZ(leftX);

    const rightX =
        horizontalLineStart.x +
        rightFactor * (horizontalLineEnd.x - horizontalLineStart.x);
    const rightZ = interpolateZ(rightX);

    if (!widestLeftPoint) widestLeftPoint = findVertex(leftX, leftZ);
    if (!widestRightPoint) widestRightPoint = findVertex(rightX, rightZ);

    if (widestLeftPoint && widestRightPoint) break;

    // Adjust factors based on side
    if (side === "left") {
      // Original logic
      if (!widestLeftPoint) leftFactor += inwardStep / 200;
      if (!widestRightPoint) rightFactor -= inwardStep / 200;
      // Safety break
      if (leftFactor > 1 || rightFactor < 0) break;
    } else {
      // Right foot logic: invert direction
      if (!widestLeftPoint) leftFactor -= inwardStep / 200;
      if (!widestRightPoint) rightFactor += inwardStep / 200;
      // Safety break
      if (leftFactor < 0 || rightFactor > 1) break;
    }
  }

  if (!widestLeftPoint || !widestRightPoint) {
    console.warn("Unable to find widest points.");
    return {width: 0, circumference: 0, length: 0};
  }

  // Draw spheres at widest points
  drawSphere(widestLeftPoint, 0xff0000);
  drawSphere(widestRightPoint, 0x0000ff);

  const width = widestLeftPoint.distanceTo(widestRightPoint);

  // Measure circumference
  const stepSize = 2;
  const yStep = 2;
  const maxDistance = 150;
  const toleranceC = 1.0;

  function findMarkerOnModelSurface(startPoint, direction) {
    for (let offset = 0; offset <= maxDistance; offset += yStep) {
      const testY = startPoint.y + direction * offset;
      for (const vertex of worldVertices) {
        if (
            Math.abs(vertex.x - startPoint.x) <= toleranceC &&
            Math.abs(vertex.z - startPoint.z) <= toleranceC &&
            Math.abs(vertex.y - testY) <= toleranceC
        ) {
          return new THREE.Vector3(vertex.x, vertex.y, vertex.z);
        }
      }
    }
    return null;
  }

  const distance = widestLeftPoint.distanceTo(widestRightPoint);
  const stepCount = Math.floor(distance / stepSize);

  const topMarkers = [];
  const bottomMarkers = [];

  for (let i = 0; i <= stepCount; i++) {
    const t = i / stepCount;
    const startPoint = new THREE.Vector3().lerpVectors(
        widestLeftPoint,
        widestRightPoint,
        t
    );

    const markerAbove = findMarkerOnModelSurface(startPoint, 1);
    const markerBelow = findMarkerOnModelSurface(startPoint, -1);

    if (markerAbove) {
      topMarkers.push(markerAbove);
      drawSphere(markerAbove, 0x00ff00); // green
    }

    if (markerBelow) {
      bottomMarkers.push(markerBelow);
      drawSphere(markerBelow, 0x0000ff); // blue
    }
  }

  function measureLineLength(markers) {
    if (markers.length < 2) return 0;
    let length = 0;
    for (let i = 0; i < markers.length - 1; i++) {
      length += markers[i].distanceTo(markers[i + 1]);
    }
    return length;
  }

  const topLineLength = measureLineLength(topMarkers);
  const bottomLineLength = measureLineLength(bottomMarkers);
  const circumference = topLineLength + bottomLineLength;

  // Measure model length
  function measureModelLengthWithLines() {
    let minZ = Infinity;
    let maxZ = -Infinity;

    for (const vertex of worldVertices) {
      if (vertex.z < minZ) minZ = vertex.z;
      if (vertex.z > maxZ) maxZ = vertex.z;
    }

    const tolerance = 1.0;
    const stepSize = 1.0;
    let forwardLineZ = maxZ + 10;
    let backwardLineZ = minZ - 10;
    let forwardContactPoint = null;
    let backwardContactPoint = null;

    function findLineContact(z) {
      let farthestTop = null;
      let farthestBottom = null;
      for (const vertex of worldVertices) {
        if (Math.abs(vertex.z - z) <= tolerance) {
          if (!farthestTop || vertex.y > farthestTop.y) farthestTop = vertex;
          if (!farthestBottom || vertex.y < farthestBottom.y)
            farthestBottom = vertex;
        }
      }
      return {farthestTop, farthestBottom};
    }

    // Move forward line backward until contact
    while (true) {
      const {farthestTop, farthestBottom} = findLineContact(forwardLineZ);
      if (farthestTop && farthestBottom) {
        forwardContactPoint = {top: farthestTop, bottom: farthestBottom};
        break;
      }
      forwardLineZ -= stepSize;
    }

    // Move backward line forward until contact
    while (true) {
      const {farthestTop, farthestBottom} = findLineContact(backwardLineZ);
      if (farthestTop && farthestBottom) {
        backwardContactPoint = {top: farthestTop, bottom: farthestBottom};
        break;
      }
      backwardLineZ += stepSize;
    }

    // Mark the contact points
    function createSphere(position, color) {
      const sphereGeometry = new THREE.SphereGeometry(1, 8, 8);
      const sphereMaterial = new THREE.MeshBasicMaterial({color});
      const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
      sphere.position.copy(position);
      scene.add(sphere);
    }

    createSphere(forwardContactPoint.top, 0xff0000);
    createSphere(forwardContactPoint.bottom, 0xff0000);
    createSphere(backwardContactPoint.top, 0x0000ff);
    createSphere(backwardContactPoint.bottom, 0x0000ff);

    const length = forwardLineZ - backwardLineZ;
    return length;
  }

  const length = measureModelLengthWithLines();

  return {width, circumference, length};
}

function rotateModelOnZPlane(side, angleInDegrees) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef} =
      side === "left"
          ? {
            footModel: footModel_left,
            scene: scene_left,
            widthRef: width_left,
            circumferenceRef: circumference_left,
            lengthRef: length_left,
          }
          : {
            footModel: footModel_right,
            scene: scene_right,
            widthRef: width_right,
            circumferenceRef: circumference_right,
            lengthRef: length_right,
          };

  if (!footModel) return;

  removeSpheresAndMeasurements(scene);
  const angleInRadians = THREE.MathUtils.degToRad(angleInDegrees);
  footModel.rotateZ(angleInRadians);
  if (rotationTimeout) clearTimeout(rotationTimeout);
  rotationTimeout = setTimeout(() => {
    const measurements = measureFoot(footModel, scene, side);
    widthRef.value = measurements.width;
    circumferenceRef.value = measurements.circumference;
    lengthRef.value = measurements.length;
  }, 1000);
}

function moveModelOnZPlane(side, xOffset, zOffset) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef} =
      side === "left"
          ? {
            footModel: footModel_left,
            scene: scene_left,
            widthRef: width_left,
            circumferenceRef: circumference_left,
            lengthRef: length_left,
          }
          : {
            footModel: footModel_right,
            scene: scene_right,
            widthRef: width_right,
            circumferenceRef: circumference_right,
            lengthRef: length_right,
          };

  if (!footModel) return;
  removeSpheresAndMeasurements(scene);
  footModel.position.x += xOffset;
  footModel.position.z += zOffset;
  if (rotationTimeout) clearTimeout(rotationTimeout);
  rotationTimeout = setTimeout(() => {
    const measurements = measureFoot(footModel, scene, side);
    widthRef.value = measurements.width;
    circumferenceRef.value = measurements.circumference;
    lengthRef.value = measurements.length;
  }, 1000);
}

function loadSTL(side, file) {
  const {scene, widthRef, circumferenceRef, lengthRef} =
      side === "left"
          ? {
            scene: scene_left,
            widthRef: width_left,
            circumferenceRef: circumference_left,
            lengthRef: length_left,
          }
          : {
            scene: scene_right,
            widthRef: width_right,
            circumferenceRef: circumference_right,
            lengthRef: length_right,
          };

  const reader = new FileReader();
  reader.onload = (e) => {
    const contents = e.target.result;
    const loader = new STLLoader();
    const geometry = loader.parse(contents);

    // Set the material to be transparent with a desired opacity
    const material = new THREE.MeshPhongMaterial({
      color: 0x909090,
      shininess: 30,
      transparent: true,
      opacity: 0.9, // Adjust this value between 0 and 1 as needed
    });

    const footModel = new THREE.Mesh(geometry, material);
    footModel.scale.set(1, 1, 1);
    footModel.castShadow = true;
    footModel.receiveShadow = true;

    centerAlignAndRotateModelToesForward(footModel, scene);
    scene.add(footModel);

    if (side === "left") {
      footModel_left = footModel;
      files_loaded_left.value = true;
    } else {
      footModel_right = footModel;
      files_loaded_right.value = true;
    }

    const measurements = measureFoot(footModel, scene, side);
    widthRef.value = measurements.width;
    circumferenceRef.value = measurements.circumference;
    lengthRef.value = measurements.length;

    visible.value = false;
  };
  reader.readAsArrayBuffer(file);
}

function removeScans() {
  // If the models exist in the scene, remove them
  if (footModel_left && scene_left) {
    scene_left.remove(footModel_left);

    // Dispose geometry and material for proper cleanup
    if (footModel_left.geometry) footModel_left.geometry.dispose();
    if (footModel_left.material) {
      if (Array.isArray(footModel_left.material)) {
        footModel_left.material.forEach((mat) => mat.dispose());
      } else {
        footModel_left.material.dispose();
      }
    }
    footModel_left = null;
  }

  if (footModel_right && scene_right) {
    scene_right.remove(footModel_right);
    if (footModel_right.geometry) footModel_right.geometry.dispose();
    if (footModel_right.material) {
      if (Array.isArray(footModel_right.material)) {
        footModel_right.material.forEach((mat) => mat.dispose());
      } else {
        footModel_right.material.dispose();
      }
    }
    footModel_right = null;
  }

  // Reset the flags and measurements
  files_loaded_left.value = false;
  files_loaded_right.value = false;
  width_left.value = null;
  width_right.value = null;
  circumference_left.value = null;
  circumference_right.value = null;
  length_left.value = null;
  length_right.value = null;

  // Remove helper objects, spheres, or measurements if any
  removeSpheresAndMeasurements(scene_left);
  removeSpheresAndMeasurements(scene_right);
}

async function navigate() {
  store.scanLengthLeft = Math.round(length_left.value);
  store.scanWidthLeft = Math.round(width_left.value);
  store.scanCircumfenceLeft = Math.round(circumference_left.value);

  store.scanLengthRight = Math.round(length_right.value);
  store.scanWidthRight = Math.round(width_right.value);
  store.scanCircumfenceRight = Math.round(circumference_right.value);

  await router.push("/osb/foot-dimensions/" + store.category);
}

// Dragging logic
function startDraggingModel(side, clientX, clientY) {
  const {camera, footModel, controls} =
      side === "left"
          ? {
            camera: camera_left,
            footModel: footModel_left,
            controls: controls_left,
          }
          : {
            camera: camera_right,
            footModel: footModel_right,
            controls: controls_right,
          };

  if (!footModel) return;

  const container = side === "left" ? target_left.value : target_right.value;
  const rect = container.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;
  raycaster.setFromCamera(mouse, camera);

  const intersects = raycaster.intersectObject(footModel);
  if (intersects.length > 0) {
    if (side === "left") {
      isDraggingLeft = true;
      controls.enabled = false;
      dragStartPositionLeft.copy(intersects[0].point);
    } else {
      isDraggingRight = true;
      controls.enabled = false;
      dragStartPositionRight.copy(intersects[0].point);
    }
  }
}

function dragModel(side, clientX, clientY) {
  const {camera, footModel, scene} =
      side === "left"
          ? {camera: camera_left, footModel: footModel_left, scene: scene_left}
          : {
            camera: camera_right,
            footModel: footModel_right,
            scene: scene_right,
          };

  if (!footModel) return;
  const dragging = side === "left" ? isDraggingLeft : isDraggingRight;
  if (!dragging) return;

  removeSpheresAndMeasurements(scene);

  const container = side === "left" ? target_left.value : target_right.value;
  const rect = container.getBoundingClientRect();
  mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
  mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
  const intersectPoint = new THREE.Vector3();
  raycaster.ray.intersectPlane(plane, intersectPoint);

  if (side === "left") {
    footModel.position.set(
        footModel.position.x + (intersectPoint.x - dragStartPositionLeft.x),
        footModel.position.y,
        footModel.position.z + (intersectPoint.z - dragStartPositionLeft.z)
    );
    dragStartPositionLeft.copy(intersectPoint);
  } else {
    footModel.position.set(
        footModel.position.x + (intersectPoint.x - dragStartPositionRight.x),
        footModel.position.y,
        footModel.position.z + (intersectPoint.z - dragStartPositionRight.z)
    );
    dragStartPositionRight.copy(intersectPoint);
  }
}

function stopDraggingModel(side) {
  const {footModel, scene, widthRef, circumferenceRef, lengthRef, controls} =
      side === "left"
          ? {
            footModel: footModel_left,
            scene: scene_left,
            widthRef: width_left,
            circumferenceRef: circumference_left,
            lengthRef: length_left,
            controls: controls_left,
          }
          : {
            footModel: footModel_right,
            scene: scene_right,
            widthRef: width_right,
            circumferenceRef: circumference_right,
            lengthRef: length_right,
            controls: controls_right,
          };

  if (side === "left" && isDraggingLeft) {
    isDraggingLeft = false;
    controls.enabled = true;
    const measurements = measureFoot(footModel, scene, side);
    widthRef.value = measurements.width;
    circumferenceRef.value = measurements.circumference;
    lengthRef.value = measurements.length;
  } else if (side === "right" && isDraggingRight) {
    isDraggingRight = false;
    controls.enabled = true;
    const measurements = measureFoot(footModel, scene, side);
    widthRef.value = measurements.width;
    circumferenceRef.value = measurements.circumference;
    lengthRef.value = measurements.length;
  }
}

// Left side mouse/touch
function onMouseDownLeft(e) {
  startDraggingModel("left", e.clientX, e.clientY);
}

function onMouseMoveLeft(e) {
  dragModel("left", e.clientX, e.clientY);
}

function onMouseUpLeft() {
  stopDraggingModel("left");
}

function onTouchStartLeft(e) {
  if (e.touches.length === 1) {
    startDraggingModel("left", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchMoveLeft(e) {
  if (e.touches.length === 1) {
    dragModel("left", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchEndLeft() {
  stopDraggingModel("left");
}

// Right side mouse/touch
function onMouseDownRight(e) {
  startDraggingModel("right", e.clientX, e.clientY);
}

function onMouseMoveRight(e) {
  dragModel("right", e.clientX, e.clientY);
}

function onMouseUpRight() {
  stopDraggingModel("right");
}

function onTouchStartRight(e) {
  if (e.touches.length === 1) {
    startDraggingModel("right", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchMoveRight(e) {
  if (e.touches.length === 1) {
    dragModel("right", e.touches[0].clientX, e.touches[0].clientY);
  }
}

function onTouchEndRight() {
  stopDraggingModel("right");
}
</script>

<style scoped>
canvas {
  border-radius: 15px !important;
}
</style>
